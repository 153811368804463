import React from 'react'
import { Link } from 'react-router-dom'

const Button = ({ className, onClick, href, text, type, icon, left }) => {
    return (
        href ? (
            <Link to={href}>
                <button className={`${className ? className : ''} h-[58px] bg-primary-color font-normal font-poppins btn-shadow rounded-lg bg-primary-text text-white cursor-pointer flex ${left ? 'flex-row-reverse':'flex-row'} items-center justify-center`} onClick={onClick ? onClick : () => { }}>{text} {icon ? icon : ''}</button>
            </Link>
        ) : (
            <button type={type ? type : "button"} className={`${className ? className : ''} h-[58px] bg-primary-color font-normal font-poppins btn-shadow rounded-lg  bg-primary-text text-white cursor-pointer flex items-center justify-center  ${left ? 'flex-row-reverse':'flex-row'}`} onClick={onClick ? onClick : () => { }}>{text} {icon ? icon : ''}</button>
        )
    )
}

export default Button