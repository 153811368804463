import React, { useState } from 'react';
import Button from '../components/Button';
import Swal from 'sweetalert2';
import { cornerBottom, mac } from '../assets';
import { Image } from 'antd';
import axios from 'axios';  // Import axios

const Home = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://fcn-staging.chatkyc.ai/api/notify', { email });

      if (response.status === 201) {  // Check for a successful response
        setEmail(''); 
        Swal.fire({
          title: 'Success!',
          text: 'You will be notified when we launch.',
          icon: 'success',
          confirmButtonText: 'OK'
        });
      } else {
        Swal.fire({
          title: 'Oops...',
          text: response.data.message,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'There was a problem with the server. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  return (
    <div className="w-full min-h-screen h-full 2xl:px-20 md:px-10 px-5 sm:py-20 py-32 sm:gap-20 gap-10 flex lg:flex-row flex-col items-center justify-between">
      <div className='lg:w-1/2 w-full flex flex-col gap-3'>
        <h1 className='w-full text-[#033BC7] lg:text-[75.377px] md:text-6xl text-4xl font-bold uppercase lg:text-left text-center font-century-gothic'>Coming Soon</h1>

        <p className='pt-[20px] w-full font-lato font-bold text-black lg:text-left text-center tracking-[-1.02px] lg:text-4xl md:text-3xl text-2xl'>
          The World’s First AI Compliance Assistant.
        </p>

        <p className='w-full pt-[10px] text-black text-xl font-medium font-lato lg:text-left text-center'>
          Trained only on data from regulators, reputable advisory bodies and industry standards.
        </p>

        <p className='w-full pt-[10px] text-black text-xl font-medium font-lato lg:text-left text-center'>
          Create and navigate policies, procedures and assessments more efficiently than you ever thought possible.
        </p>
        
        <div className='lg:max-w-[789px] w-full flex flex-col lg:items-start items-center gap-4 md:mt-[22px] mt-[16px]'>
          <p className='w-full text-[#090B1E] self-stretch lg:text-left text-center text-base font-bold'>
            Enter your email address below to get free access for one month.
          </p>

          {/* Searchbar */}
          <form className='flex items-center justify-between md:gap-5 gap-3 w-full'>
            <input
              type="text"
              className='h-[58px] flex-grow search-container-shadow py-5 px-4 text-sm focus:outline-none rounded-md'
              autoComplete='off'
              placeholder='Enter your Email Address'
              value={email}  // Bind the input value to the state
              onChange={(e) => setEmail(e.target.value)}  // Handle input changes
            />
            <Button
              text={'Notify Me'}
              onClick={handleSubmit}
              type='submit'
              className={'max-w-[181px] md:w-full py-3 lg:px-8 md:px-6 px-4 text-base font-bold text-white'}
            />
          </form>
        </div>
      </div>

      <div className='lg:w-1/2 w-full flex items-center justify-center '>
        <Image.PreviewGroup>
          <Image src={mac} alt="mac" className="w-full" />
        </Image.PreviewGroup>
      </div>
    </div>
  );
};

export default Home;
