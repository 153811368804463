import React from 'react'
import { logo } from '../assets'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <div className={`max-w-[1676px] w-full xl:pt-[20px] xl:pb-[10px] pt-3 pb-2 flex items-center 2xl:px-20 md:px-10 px-5 absolute top-0 left-1/2 -translate-x-1/2 z-50`}>
            <Link to="/">
                <img src={logo} alt="logo" className='xl:h-[40px] h-[30px]' />
            </Link>
        </div>
    )
}

export default Header