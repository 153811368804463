import { Route, Routes } from "react-router-dom";
import Home from "./app/Home";
import Header from "./components/Header";
import { cornerBottom, Cornertop } from "./assets";

function App() {

  return (
    <div className="w-full min-h-screen h-full flex items-center flex-col relative overflow-hidden">
      {/* Header */}
      <Header />

      {/* Effect */}
      <>
        <div className="fixed -top-[200px] -left-[400px] w-[1625.824px] h-[584.596px] rounded-[1625.824px] rotate-[-3.416deg] bg-[#8467FF] opacity-20 blur-[107.25px]" />
        <div className="fixed -bottom-[200px] -right-[400px] w-[1724.38px] h-[597.145px] rounded-[1625.824px] rotate-[-3.416deg] bg-[#0085FF] opacity-20 blur-[107.25px]" />
        {/* <img src={Cornertop} alt="top-effect" className="max-[1776px]:w-[510px] opacity-30 lg: 2xl:w-[470px] lg:w-[420px] sm:w-[320px] w-[0px] fixed top-0 right-10" />
        <img src={cornerBottom} alt="top-effect" className="max-[1776px]:w-[510px] opacity-30 2xl:w-[470px] lg:w-[420px] sm:w-[320px] w-[180px] fixed  bottom-10 left-10" /> */}
      </>

      <div className="max-w-[1676px] w-full relative">
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
